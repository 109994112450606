import React from 'react';
import './EmailVerificationInfo.scss'; // Import the corresponding SCSS file for styles


    const emailServiceData = {
        howItWorks: {
          title: "How It Works?",
          description: "You are in the right place to know if an email is valid, active, and able to receive & send emails. Use our free online tools to check emails one by one.",
          steps: [
            "It checks whether the email format is correct or not.",
            "It checks if the domain is valid or not.",
            "Finally, it technology checks if the mailbox really exists or not; it extracts the domain MX record and sends a message over SMTP."
          ],
          warning: "The email verification process does the validation without sending an email. In some cases, some mail servers do not cooperate in this process, so the results may not be as accurate as expected.",
          queries: "Always protect your email account with 2-step verification so that no one can misuse the email account."
        },
        benefits: {
          title: "What Are the Benefits of Using This Tool?",
          description: "Mailverifyservice helps people involved in email marketing, data centers, and lead generation groups. It is crucial to reduce email bounces if you are running email campaigns and marketing."
        },
        recovery: {
          title: "How to recover my email server if email account recovery option not available in my portal?",
          description: "Contact Customer Support: If the email service is provided by a company or organization, reach out to their customer support for assistance. Provide them with all the relevant details about your account."
        },
        contact: {
          title: "Want to Reduce Bounce Rate for Your Email Campaign?",
          description: "Contact us to get details of the bulk email verification process and take review before buying packages.",
          phone: "+1 833-889-1827",
          imageUrl: "https://static.tapfiliate.com/5c900d55ca344.jpg?a=46179-ade7e0&s=1135997-0ef4a7"
        }
      };
const EmailVerificationInfo = () => {
    return (
        <div className="email-verification-info-container">
            <div className="info-section">
                <h1>{emailServiceData.howItWorks.title}</h1>
                <p>{emailServiceData.howItWorks.description}</p>
                <ol>
                    {emailServiceData.howItWorks.steps.map((step, index) => (
                        <li key={index}>{step}</li>
                    ))}
                </ol>
                <p>{emailServiceData.howItWorks.warning}</p>
                <p>{emailServiceData.howItWorks.queries}</p>
            </div>

            <div className="info-section">
                <h2>{emailServiceData.benefits.title}</h2>
                <p>{emailServiceData.benefits.description}</p>
                <h2>{emailServiceData.recovery.title}</h2>
                <p>{emailServiceData.recovery.description}</p>
            </div>
            <div className="info-section contact-info">
                <h2>{emailServiceData.contact.title}</h2>
                <p>{emailServiceData.contact.description}</p>
                <p>Contact: {emailServiceData.contact.phone}</p>
                <img src={emailServiceData.contact.imageUrl} alt="Contact Image" style={{ height: "200px", width: "200px" }} />
            </div>
        </div>
    );
};

export default EmailVerificationInfo;
