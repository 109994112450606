import React, { useState } from 'react';
import styles from './Services.module.scss'; // Import the corresponding SCSS file for styles

const Services = () => {
  const [inputText, setInputText] = useState('');
  const [extractedEmails, setExtractedEmails] = useState([]);

  const extractEmails = () => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    const emails = inputText.match(emailRegex);
    setExtractedEmails(emails ? Array.from(new Set(emails)) : []); // Remove duplicates
    if(!extractEmails){
      alert("You have not entered the correct email please check")
    }
  };

  return (
    <div className={styles.servicesContainer}>
      <div className={styles.formContainer}>
        <h1>Services</h1>
        <h2>Free Email Extractor</h2>
        <p>
          Instruction: Copy and paste garbage text here and click on the Extractor button to get only the list of emails as a result.
        </p>

        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Paste your text here..."
          className={styles.textArea}
        />

        <button onClick={extractEmails} className={styles.extractButton}>
          Extract Emails
        </button>

        {extractedEmails.length > 0 && (
          <div className={styles.resultContainer}>
            <h3>Extracted Emails:</h3>
            <ul>
              {extractedEmails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className={styles.imageContainer}>
        <img
          src="https://static.tapfiliate.com/5c900d55ca344.jpg?a=46179-ade7e0&s=1135997-0ef4a7"
          alt="Email Extraction"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default Services;
