import React from 'react';
import styles from './index.module.scss'; // Import the corresponding SCSS file for styles

const PrivacyPolicy = () => {
  const policySections = [
    {
      title: "Email Address Submitted",
      content: "We collect email addresses to provide our services but do not store them without user consent."
    },
    {
      title: "Log Files",
      content: "We utilize log files to analyze traffic patterns and improve our service."
    },
    {
      title: "Cookies and Web Beacons",
      content: "Our website uses cookies to enhance user experience. You can choose to accept or decline cookies."
    },
    {
      title: "When you contact our support",
      content: "If you reach out via email or call, we keep conversations for up to 30 days to ensure quality support. We do not share your personal information with third parties."
    },
    {
      title: "Third Party Links",
      content: "We may include links to third-party sites, which have their own privacy policies. We are not responsible for their content."
    },
    {
      title: "Do we disclose any information to outside parties?",
      content: "We do not sell, trade, or transfer your personal information. However, we may share data with trusted partners who assist us in operating our website, provided they agree to keep it confidential."
    },
    {
      title: "Security",
      content: "We take appropriate security measures to protect your personal information. However, no method of transmission over the internet is 100% secure."
    },
    {
      title: "Disclosure of Personal Information",
      content: "We may disclose your personal information to service providers to help fulfill your requests, ensuring they also adhere to confidentiality."
    },
    {
      title: "Collection of Personal Information",
      content: "We collect personal information to improve our services and may contact you with updates or promotional materials."
    },
  ];

  return (
    <div className={styles.privacyContainer}>
      <h1>Privacy Policy</h1>
      <div className={styles.policyGrid}>
        {policySections.map((section, index) => (
          <div key={index} className={styles.policyCard}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </div>
        ))}
      </div>
      <p>Please check our Terms & Conditions.</p>
    </div>
  );
};

export default PrivacyPolicy;
