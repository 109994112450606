import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmailVerification from '../../components/EmailVerification/EmailVerification';
import EmailVerificationInfo from '../../components/EmailVerificationInfo/EmailVerificationInfo';
import Footer from '../../components/Footer/Footer';

function Index() {
  return (
    <>
      <EmailVerification />
      <EmailVerificationInfo />
    </>
  );
}

export default Index;
