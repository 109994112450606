import React from 'react';
import styles from './index.module.scss'; // Import the corresponding SCSS file for styles

const TermsConditions = () => {
  return (
    <div className={styles.termsContainer}>
      <h1>Terms & Conditions</h1>

      <p>
        PLEASE READ THESE TERMS & CONDITIONS CAREFULLY BEFORE USING OUR SERVICES, AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS. THIS AGREEMENT MAY BE UPDATED FROM TIME TO TIME. By using our website, you acknowledge that you have read and understood these Terms & Conditions.
      </p>

      <p>
        By accessing our services, you confirm that you are legally capable of entering into this agreement. If you do not agree to these Terms or are not legally eligible, please refrain from using our services.
      </p>

      <h2>Data Transfer and Processing</h2>
      <p>
        To provide our services effectively, you consent to our processing, transfer, and storage of your information in the US and other countries, which may not offer the same level of data protection as your local jurisdiction.
      </p>

      <h2>Links to Our Site</h2>
      <p>
        You are granted a limited, non-exclusive right to create a hypertext link to our homepage, provided the link does not portray our services or brand in a misleading or negative manner. This right may be revoked at any time.
      </p>
      <p>
        You may not use framing techniques to enclose any of our trademarks, logos, or proprietary information without express written permission. All other linking requires prior approval from our team. This agreement does not grant you any rights or licenses under our intellectual property rights or those of any third party.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions regarding our Terms and Conditions or our practices, please reach out to us.
      </p>
      <p>Email: <a href="mailto:help@emailservicedata.info">help@emailservicedata.info</a></p>
    </div>
  );
};

export default TermsConditions;
