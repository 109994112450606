import React, { useState } from 'react';
import './EmailVerification.scss'; // Create a corresponding SCSS file for styles

const EmailVerification = () => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');

  const getRandomBoolean = () => Math.random() >= 0.5; // Randomly returns true or false
  const getRandomScore = () => (Math.random() * 100).toFixed(2); // Random score between 0 and 100

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate syntax (this is a simple validation check)
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    // Simulated response from an email verification service
    const mockResponse = {
      email_address: email,
      domain: email.split('@')[1] || '',
      is_free: getRandomBoolean() ? "True" : "False",
      is_syntax: isValidEmail ? "True" : "False", // Always set based on email validity
      is_domain: getRandomBoolean() ? "True" : "False",
      is_smtp: getRandomBoolean() ? "True" : "False",
      is_verified: getRandomBoolean() ? "True" : "False",
      is_server_down: getRandomBoolean() ? "True" : "False",
      is_greylisted: getRandomBoolean() ? "True" : "False",
      is_disposable: getRandomBoolean() ? "True" : "False",
      is_suppressed: getRandomBoolean() ? "True" : "False",
      is_role: getRandomBoolean() ? "True" : "False",
      is_high_risk: getRandomBoolean() ? "True" : "False",
      is_catchall: getRandomBoolean() ? "True" : "False",
      mailboxvalidator_score: getRandomScore(),
      time_taken: (Math.random() * 2).toFixed(4), // Random time between 0 and 2 seconds
      status: getRandomBoolean() ? "True" : "False",
      credits_available: Math.floor(Math.random() * 500), // Random credits between 0 and 500
      error_code: "",
      error_message: ""
    };
    setEmail('')
    // Set the result to display in the text area
    setResult(JSON.stringify(mockResponse, null, 2));
  };

  return (
    <div className="email-verification-container">
      <h1>Issue With Your Email Account?</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="email-input"
        />
        <button type="submit" className="submit-button">Verify</button>
      </form>
      <p className="notice">Note: We never store your email address in our database.</p>

      <h2>Verification Result</h2>
      <textarea
        className="result-textarea"
        value={result}
        readOnly
      />

      <div className="contact-info">
        <h2>Get details & review for bulk email verification</h2>
        <a href="tel:+18338891827" style={{ textDecoration: 'none' ,fontSize:"3rem"}}>    <p> Let's Talk: +1 833-889-1827</p></a>
      </div>
    </div>
  );
};

export default EmailVerification;
