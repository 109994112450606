import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import styles from './index.module.scss'; // Import the corresponding SCSS file

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerSection}>
        <h3>Company</h3>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/service">Services</Link></li>
          <li><Link to="/aboutus">About Us</Link></li>
          {/* <li><Link to="/privacy-policy">Policy</Link></li> */}
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          <li><Link to="/terms">Terms</Link></li>
          {/* <li><Link to="/faq">FAQ</Link></li> */}
        </ul>
      </div>

      <div className={styles.footerSection}>
        <h3>Address</h3>
        <p>
          H-42, F-Block,<br />
          Sector 63,<br />
          Noida, 201305,<br />
          India
        </p>

      </div>

      <div className={styles.footerSection}>
        <h3>Contact Us</h3>
        <a href="tel:+18338891827 " style={{ textDecoration: 'none' }}>    <p> Let's Talk: +1 833-889-1827</p></a>
        <p>help@emailservicedata.info</p>
      </div>

      {/* <div className={styles.footerSection}>
        <h3>Social</h3>
        <div className={styles.socialIcons}> */}
      {/* Example social icons */}
      {/* <a href="#" className={styles.socialIcon}>FB</a>
          <a href="#" className={styles.socialIcon}>TW</a>
          <a href="#" className={styles.socialIcon}>IG</a> */}
      {/* </div>
      </div> */}
    </footer>
  );
};

export default Footer;
