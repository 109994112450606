import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../public/logo.png'; // Ensure the logo path is correct
import styles from './index.module.scss';

function Index() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/"> <img src={logo} alt="Logo" /></Link>
      </div>
      
      {/* <div className={styles.phoneNumber}>
        <a href="tel:+18338891827" style={{color:"#0000EE"}}>Contact us :+1 833-889-1827</a>
      </div> */}

      <div className={styles.hamburger} onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
        <li onClick={toggleMenu}><Link to="/">Home</Link></li>
        <li onClick={toggleMenu}><Link to="/service">Service</Link></li>
        <li onClick={toggleMenu}><Link to="/aboutus">About Us</Link></li>
        <li onClick={toggleMenu}><Link to="/contactus">Contact Us</Link></li>
      </ul>
    </nav>
  );
}

export default Index;
